<template>
  <div class="card card-custom">
    <div class="page-title pageheading" style="height: 50px;">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Create Clients</span>
      </h3>
    </div>

    <div class="card-body p-0">
      <form class="form mx-5">
        <h4 class="mt-10 font-weight-bold text-dark">
          Enter Account Details
        </h4>
        <v-avatar v-if="url != null" size="128">
          <img :src="url" alt="John" />
        </v-avatar>
        <v-file-input
          @change="Preview_image"
          v-model="image"
          :rules="rules"
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Choose Image"
          prepend-icon="mdi-camera"
          label="Profile Image"
        ></v-file-input>

        <div class="row">
          <div class="col-md-6">
            <!-- firstName -->
            <div class="form-group">
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="fname"
                v-model="form.firstName"
                placeholder="First Name"
              />
              <span class="form-text text-muted">Please enter First name.</span>
            </div>
          </div>
          <div class="col-md-6">
            <!-- firstName -->
            <div class="form-group">
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="mname"
                v-model="form.middleName"
                placeholder="Middle Name"
              />
              <span class="form-text text-muted"
                >Please enter Middle name.</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- firstName -->
            <div class="form-group">
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="lname"
                placeholder="Last Name"
                v-model="form.lastName"
              />
              <span class="form-text text-muted">Please enter last name.</span>
            </div>
          </div>
          <div class="col-md-6">
            <!-- firstName -->
            <div class="form-group">
              <select
                name="Saluation"
                placeholder="Saluation"
                class="form-control form-control-solid form-control-lg"
                v-model="form.Saluation"
              >
                <option value="" disabled selected hidden
                  >Choose Saluation</option
                >
                <option value="Dr.">Dr.</option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
              </select>
              <span class="form-text text-muted">Please select Saluation.</span>
            </div>
          </div>
        </div>

        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Setup Current Location
          </h4>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="address1"
                  placeholder="Address"
                  v-model="form.address"
                />
                <span class="form-text text-muted">Please enter Address.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- postCode -->
            <div class="col-md-6">
              <!-- country -->
              <div class="form-group">
                <select
                  name="country"
                  placeholder="Country"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.country"
                >
                  <option value="" disabled selected hidden
                    >Choose Country</option
                  >
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Åland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU" selected>Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia, Plurinational State of</option>
                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD"
                    >Congo, the Democratic Republic of the</option
                  >
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Côte d'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curaçao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and McDonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP"
                    >Korea, Democratic People's Republic of</option
                  >
                  <option value="KR">Korea, Republic of</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK"
                    >Macedonia, the former Yugoslav Republic of</option
                  >
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Réunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthélemy</option>
                  <option value="SH"
                    >Saint Helena, Ascension and Tristan da Cunha</option
                  >
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin (French part)</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten (Dutch part)</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS"
                    >South Georgia and the South Sandwich Islands</option
                  >
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM"
                    >United States Minor Outlying Islands</option
                  >
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela, Bolivarian Republic of</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.S.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
                <span class="form-text text-muted">Please Choose Country.</span>
              </div>
            </div>
            <div class="col-md-6">
              <!-- state -->
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="state"
                  placeholder="State"
                  v-model="form.state"
                />
                <span class="form-text text-muted">Please enter State.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- city -->
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="city"
                  placeholder="City"
                  v-model="form.city"
                />
                <span class="form-text text-muted">Please enter City.</span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="zipcode"
                  placeholder="Zip Code"
                  v-model="form.zipCode"
                />
                <span class="form-text text-muted">Please enter Zip Code.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- city -->
              <div class="form-group">
                <select
                  name="Saluation"
                  placeholder="Saluation"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.Saluation"
                >
                  <option value="" disabled selected hidden
                    >Choose Territory</option
                  >
                  <option value="Specfic.">Specific.</option>
                  <option value="General.">General.</option>
                </select>
                <span class="form-text text-muted"
                  >Please enter Territory.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Setup Personal Information
          </h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locaddress1"
                  placeholder="Tell #"
                  v-model="form.tellnum"
                />
                <span class="form-text text-muted"
                  >Please enter Tell home.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="loccell"
                  placeholder="Cell #"
                  v-model="form.cellnum"
                />
                <span class="form-text text-muted"
                  >Please enter Cell Number.</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locssn"
                  placeholder="SSN"
                  v-model="form.ssn"
                />
                <span class="form-text text-muted">Please enter SSN.</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <select
                  name="locgender"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.gender"
                >
                  <option value="" selected>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <span class="form-text text-muted">Please select gender.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locssn"
                  placeholder="Martial Status"
                  v-model="form.martialstatus"
                />
                <span class="form-text text-muted"
                  >Please enter Martial Status.</span
                >
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locssn"
                  placeholder="Weight"
                  v-model="form.weight"
                />
                <span class="form-text text-muted">Please enter Weight.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Inquiry Date</label>
                <b-form-datepicker
                  v-model="form.Inquirydate"
                  locale="en"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Admission Date*</label>
                <b-form-datepicker
                  v-model="form.admissionDate"
                  locale="en"
                ></b-form-datepicker>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>DOB</label>
                <b-form-datepicker
                  v-model="form.DOB"
                  locale="en"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <v-checkbox
                  v-model="form.checkbox"
                  label="New Born"
                ></v-checkbox>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <select
                  name="locrace"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.race"
                >
                  <option value="" disabled selected hidden>select Race</option>
                  <option value="African American">African American</option>
                </select>
                <span class="form-text text-muted">Please select Race.</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Email"
                  v-model="form.email"
                />
                <span class="form-text text-muted">Please enter Email.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Enter DB Details
          </h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Group</label>
                <select
                  name="locgroup"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.group"
                >
                  <option value="" selected>Select Group</option>
                  <option value="">TODO</option>
                </select>
                <span class="form-text text-muted">Please select GROUP.</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Type</label>
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.type"
                >
                  <option value="" selected>Select Type</option>
                  <option value="">TODO</option>
                </select>
                <span class="form-text text-muted">Please select GROUP.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Hired</label>
                <b-form-datepicker
                  v-model="form.hire"
                  locale="en"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Term</label>
                <b-form-datepicker
                  v-model="form.term"
                  locale="en"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Assessment Type*</label>
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.Assementtype"
                >
                  <option value="" selected>Select Assessment Type</option>
                  <option value="Manual Assessment">Manual Assessment</option>
                  <option value="Online Assessment">Online Assessment</option>
                </select>
                <span class="form-text text-muted"
                  >Please select Assessment.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Admission Type*</label>
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.Assementtype"
                >
                  <option value="ALP (Assisted Living Placement)"
                    >ALP (Assisted Living Placement)</option
                  >
                  <option value="IHC (In-Home Care)">IHC (In-Home Care)</option>
                </select>
                <span class="form-text text-muted"
                  >Please select Assessment.</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Client Type*</label>
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.ClientType"
                >
                  <option value="" disabled selected hidden
                    >Please Choose Client Type</option
                  >
                  <option value="Foot Care">Foot Care</option>
                  <option value="Home Care">Home Care</option>
                  <option value="Other">Other</option>
                  <option value="Transportation">Transportation</option>
                </select>
                <span class="form-text text-muted"
                  >Please select Assessment.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Re-assessment After*</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Re-assessment After"
                  v-model="form.Reassessment"
                />
                <span class="form-text text-muted"
                  >Please Enter Reassessment.</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Place Of Service</label>
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.PlaceService"
                >
                  <option value="" disabled selected hidden
                    >Please Choose Place Of Service</option
                  >
                  <option value="Pharmacy">Pharmacy</option>
                  <option value="School">School</option>
                </select>
                <span class="form-text text-muted"
                  >Please select PlaceService.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Default Service Type</label>
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.PlaceService"
                >
                  <option value="" disabled selected hidden
                    >Please Choose Default Service Type</option
                  >
                  <option value="Administrative">Administrative</option>
                  <option value="Assessment">Assessment</option>
                </select>
                <span class="form-text text-muted"
                  >Please select Default Service Type.</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Medical Id"
                  v-model="form.medicalId"
                />
                <span class="form-text text-muted"
                  >Please Enter Aalternate Medical ID.</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Alternate Medical Id"
                  v-model="form.alternatemedicalId"
                />
                <span class="form-text text-muted"
                  >Please Enter Alternate Medical ID.</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Clock-in/ Clock-out Radius (in feet) "
                  v-model="form.clock"
                />
                <span class="form-text text-muted"
                  >Please Enter Clock-in/ Clock-out Radius (in feet) .</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <v-checkbox
                  v-model="form.checkbox"
                  label="Charge Tax"
                ></v-checkbox>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <v-checkbox
                  v-model="form.checkbox"
                  label="Exempt Client for OT"
                ></v-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- <div class="form-group">
                <v-checkbox
                  v-model="form.checkbox"
                  label="Make visit note(s) mandatory"
                ></v-checkbox>
              </div> -->
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <v-checkbox
                  v-model="form.checkbox"
                  label="Split Overnight Shift Applicable"
                ></v-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-2">
              <div class="form-group">
                <v-checkbox
                  v-model="form.checkbox"
                  label="Send Client Portal Credentials"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Referal
          </h4>
          <div class="wizard-desc">
            Select referral type. Fields are mandatory/optional based on the
            type.
          </div>

          <!-- firstName -->
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="fname"
              v-model="form.refered"
              placeholder="Refered by"
            />
            <span class="form-text text-muted">Please enter Refered by.</span>
          </div>
          <div class="form-group">
            <label>Referral Date</label>
            <b-form-datepicker
              v-model="form.DOB"
              locale="en"
            ></b-form-datepicker>
          </div>
          <!-- lastName -->
          <div class="form-group">
            <textarea
              placeholder="Note"
              class="form-control form-control-solid form-control-lg"
              v-model="form.notes"
              locale="en"
            ></textarea>
            <span class="form-text text-muted">Please enter Refer Notes.</span>
          </div>
        </div>

        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Notes
          </h4>
          <div class="wizard-desc">
            Enter notes for this client, if any..
          </div>

          <!-- firstName -->
          <div class="form-group">
            <textarea
              placeholder="Office Notes"
              class="form-control form-control-solid form-control-lg"
              v-model="form.onotes"
              locale="en"
            ></textarea>
            <span class="form-text text-muted">Please enter Office Notes.</span>
          </div>
          <div class="form-group">
            <textarea
              placeholder="Summary Notes"
              class="form-control form-control-solid form-control-lg"
              v-model="form.notes"
              locale="en"
            ></textarea>
            <span class="form-text text-muted"
              >Please enter Summary Notes.</span
            >
          </div>
        </div>
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <button
              v-on:click="submit"
              class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
            >
              Submit
            </button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "Wizard-2",
  data() {
    return {
      // Remove this dummy login info
      form: {
        race: "",
        Saluation: "",
        Territory: "",
        checkbox: false,
        firstName: "",
        middleName: "",
        lastName: "",
        country: "",
        state: "",
        address: "",
        postcode: "",
        city: "",
        gender: "",
        cellnum: "",
        email: "",
        DOB: "",
        notes: "",
        ssn: "",
        hire: "",
        term: "",
      },
      url: null,
      image: null,
    };
  },
  mounted() {},
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
  },
};
</script>
<style scoped></style>
